@import "../variables.scss";
// footer css here
.footer__section {
  background: var(--primary-color);
  padding: 15px 0;
  @media #{$small__mobile} {
    padding: 18px 0;
  }
}
.footer__inner {
  @media #{$small__mobile} {
    flex-direction: column;
  }
}
.copyright__content {
  color: var(--white-color);
  margin-bottom: 0;
  text-transform: capitalize;
  @media #{$small__mobile} {
    margin-bottom: 18px;
    text-align: center;
  }
  &--link {
    &:hover {
      color: var(--secondary-color);
    }
  }
}

.footer__social {
  &--list {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
    @media #{$small__mobile} {
      margin-right: 10px;
    }
  }
  &--link {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    display: inline-block;
    text-align: center;
    color: var(--primary-color);
    border-radius: 50%;
    background: var(--white-color);
    &:hover {
      color: var(--white-color);
      background: var(--secondary-color);
    }
    @media #{$small__mobile} {
      width: 36px;
      height: 36px;
      line-height: 36px;
      font-size: 16px;
    }
  }
}

.mobile__menu--open {
  display: none;
}
@media only screen and (max-width: 991px) {
  .mobile__menu--open {
    display: block;
  }
}
.offcanvs__moile_menu {
  position: fixed;
  background: #ffff;
  left: 0;
  top: 0;
  z-index: 999;
  width: 300px;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transform: translateX(-100%);
}

.offcanvs__moile_menu.active,
.body__overlay.active {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}
.body__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
button[class*="styles_scroll-to-top"] {
  z-index: 99;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  transition: 0.4s;
}
button[class*="styles_scroll-to-top"]:hover {
  background: linear-gradient(109.6deg, #7412cb 11.2%, #e62e83 91.2%);
}

button[class*="styles_scroll-to-top"] svg {
  height: 20px;
}
button[class*="styles_scroll-to-top"]:hover svg {
  fill: #fff;
}

@media only screen and (max-width: 575px) {
  .portfolio__info--view {
    margin-top: 30px;
  }
}
.contact__form--btn {
  margin: 8px 0;
}

//Font Family
$poppins: "Poppins", sans-serif;

$lato: "Lato", sans-serif;

$bg_grey_color: #f7f7fb;

//Responsive Variables
$extra__large__device: "only screen and (min-width: 1200px) and (max-width: 1600px)";
$extra__large__inner: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$extra__large__inner2: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$large__device: "only screen and (min-width: 992px) and (max-width: 1199px)";
$medium__device: "only screen and (min-width: 768px) and (max-width: 991px)";
$small__mobile: "only screen and (max-width: 767px)";
$extra__small: "only screen and (max-width: 575px)";
$extra__small__inner: "only screen and (min-width: 480px) and (max-width: 575px)";
$extra__small2: "only screen and (max-width: 479px)";

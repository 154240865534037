@import "../variables.scss";
// blog details css here
.blog__details--section {
  &.mb-95 {
    @media #{$medium__device} {
      margin-bottom: 75px;
    }
    @media #{$small__mobile} {
      margin-bottom: 48px;
    }
  }
}
.post__header--title {
  @media #{$small__mobile} {
    line-height: 30px;
  }
}
.blog__post--meta {
  text-transform: capitalize;
  &__link {
    color: var(--secondary-color);
    &:hover {
      color: var(--secondary-color);
      text-decoration: underline;
    }
  }
}

.blog__details--thumbnail {
  &.mb-40 {
    @media #{$medium__device} {
      margin-bottom: 30px;
    }
    @media #{$small__mobile} {
      margin-bottom: 25px;
    }
  }
  &__img {
    border-radius: 10px;
  }
}

.blog__details--content {
  &__subtitle {
    line-height: 30px;
    @media #{$medium__device} {
      margin-bottom: 20px !important;
    }
    @media #{$small__mobile} {
      margin-bottom: 20px !important;
    }
  }
}

.blockquote__content {
  background: #f7f7fb;
  padding: 40px 70px;
  text-align: center;
  border-radius: 10px;
  @media #{$medium__device} {
    padding: 30px 50px;
  }
  @media #{$small__mobile} {
    padding: 25px 40px;
  }
  @media #{$extra__small} {
    padding: 25px 20px;
  }
  &--desc {
    font-size: 20px;
    line-height: 34px;
    font-style: italic;
    font-weight: 500;
    @media #{$small__mobile} {
      font-size: 17px;
      line-height: 32px;
    }
    @media #{$extra__small} {
      font-size: 16px;
      line-height: 30px;
    }
  }
  &.mb-30 {
    @media #{$small__mobile} {
      margin-bottom: 22px;
    }
  }
}

.blog__details--content {
  &__desc {
    &.mb-30 {
      @media #{$small__mobile} {
        margin-bottom: 22px;
      }
    }
  }
  &__step {
    &.mb-40 {
      @media #{$small__mobile} {
        margin-bottom: 25px;
      }
    }
  }
}
.post__tag--list {
  margin-right: 12px;
  &:last-child {
    margin-right: 0;
  }
  @media #{$medium__device} {
    margin-right: 8px;
  }
  @media #{$small__mobile} {
    margin-right: 9px;
    margin-bottom: 9px;
  }
}
.post__tag--wrapper {
  @media #{$small__mobile} {
    flex-wrap: wrap;
  }
}
.post__tag--link {
  background: #f7f7fb;
  padding: 6px 15px;
  border-radius: 5px;
  font-weight: 500;
  &:hover {
    background: var(--secondary-color);
    color: var(--white-color);
  }
  @media #{$medium__device} {
    padding: 5px 13px;
    font-size: 14px;
  }
  @media #{$small__mobile} {
    padding: 4px 13px;
    font-size: 14px;
  }
}
.post__tag--dark {
  background: var(--dark-color2);
  &:hover {
    background: var(--secondary-color);
  }
}
.comments__form {
  &.mb-95 {
    @media #{$medium__device} {
      margin-bottom: 75px;
    }
    @media #{$small__mobile} {
      margin-bottom: 55px;
    }
  }
}

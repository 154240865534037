// home dark css here
.header--sticky.dark--sticky.sticky {
  background: var(--primary-color);
}
.hero__dark--bg {
  background: url(../img/other/hero-dark-slider.jpg);
}
.dark__contact--form {
  & input::-webkit-input-placeholder {
    color: #c5c5c5;
  }
  & input::-moz-placeholder {
    color: #c5c5c5;
  }
  & input:-ms-input-placeholder {
    color: #c5c5c5;
  }
  & input:-moz-placeholder {
    color: #c5c5c5;
  }
  & input {
    color: #c5c5c5;
  }
  & textarea::-webkit-input-placeholder {
    color: #c5c5c5;
  }
  & textarea::-moz-placeholder {
    color: #c5c5c5;
  }
  & textarea:-ms-input-placeholder {
    color: #c5c5c5;
  }
  & textarea:-moz-placeholder {
    color: #c5c5c5;
  }
  & textarea {
    color: #c5c5c5;
  }
}

.portfolio__filter--button.text__primary {
  &.active {
    color: var(--white-color) !important;
  }
  &:hover {
    color: var(--white-color) !important;
  }
}
.social__share--link.text__primary {
  &:hover {
    color: var(--white-color) !important;
  }
}

.template__dark--color .header__sub--menu {
  background: var(--primary-color);
}
.template__dark--color blockquote {
  background: var(--dark-color2);
}

.template__dark--color a.post__tag--link {
  background: var(--dark-color2);
}
.template__dark--color a.post__tag--link:hover {
  background: var(--secondary-color);
}
.template__dark--color button.accordion-button {
  color: #fff;
  border-color: #fff;
  background: #000;
}
.template__dark--color .accordion-body {
  border-color: #fff;
}
.template__dark--color .accordion-collapse {
  border-color: #fff;
}
button.mobile__menu--open__icon {
  color: #000;
}
.template__dark--color .offcanvs__moile_menu {
  background: var(--primary-color);
}
.template__dark--color .offcanvas__sub_menu_toggle::before,
.template__dark--color .offcanvas__sub_menu_toggle::after {
  background-color: #fff;
}
.template__dark--color .offcanvas__sub_menu_item {
  color: var(--white-color);
}
.template__dark--color .header--sticky.sticky {
  background: var(--primary-color);
}
.template__dark--color .contact__form--input,
.template__dark--color .contact__form--textarea {
  color: var(--white-color);
}

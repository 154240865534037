@import "../variables.scss";
// portfolio details css here
.portfolio__details--section {
  &.mb-95 {
    @media #{$medium__device} {
      margin-bottom: 70px;
    }
    @media #{$small__mobile} {
      margin-bottom: 48px;
    }
  }
}
.portfolio__details--thumbnail {
  &.mb-50 {
    @media #{$medium__device} {
      margin-bottom: 30px;
    }
    @media #{$small__mobile} {
      margin-bottom: 30px;
    }
  }
  &__img {
    border-radius: 10px;
  }
}
.portfolio__info--content {
  @media #{$medium__device} {
    margin-bottom: 30px;
  }
  @media #{$small__mobile} {
    margin-bottom: 30px;
  }
}
.portfolio__info {
  &--list {
    margin-bottom: 12px;
    font-size: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    @media #{$small__mobile} {
      margin-bottom: 9px;
      font-size: 15px;
    }
  }
  &--label {
    font-weight: 600;
    margin-right: 12px;
    width: 85px;
  }
}
.related__post--section {
  &.mb-95 {
    @media #{$medium__device} {
      margin-bottom: 75px;
    }
    @media #{$small__mobile} {
      margin-bottom: 55px;
    }
  }
  &.mb-90 {
    @media #{$medium__device} {
      margin-bottom: 70px;
    }
    @media #{$small__mobile} {
      margin-bottom: 53px;
    }
  }
}
.related__post--thumb {
  &.mb-20 {
    @media #{$small__mobile} {
      margin-bottom: 15px;
    }
  }
}
.related__post--img {
  border-radius: 10px;
}
.related__post--title {
  &__link {
    &:hover {
      color: var(--secondary-color);
    }
  }
}

.related__dark {
  padding: 95px 0;
  @media #{$medium__device} {
    padding: 75px 0;
  }
  @media #{$small__mobile} {
    padding: 55px 0;
  }
}

@import "../variables.scss";
// error css here
.error__section {
  background: #f7f7fb;
  padding: 306px 0 238px;
  @media #{$large__device} {
    padding: 265px 0 194px;
  }
  @media #{$medium__device} {
    padding: 242px 0 165px;
  }
  @media #{$small__mobile} {
    padding: 185px 0 105px;
  }
  @media #{$extra__small2} {
    padding: 160px 0 80px;
  }
  &.dark__error {
    background: #f7f7fb;
    padding: 222px 0 238px;
    @media #{$large__device} {
      padding: 180px 0 194px;
    }
    @media #{$medium__device} {
      padding: 155px 0 165px;
    }
    @media #{$small__mobile} {
      padding: 98px 0 105px;
    }
    @media #{$extra__small2} {
      padding: 89px 0 95px;
    }
  }
}

.error__content {
  &--maintitle {
    font-size: 110px;
    line-height: 120px;
    font-weight: 900;
    margin-bottom: 25px;
    @media #{$large__device} {
      font-size: 90px;
      line-height: 100px;
    }
    @media #{$medium__device} {
      font-size: 80px;
      line-height: 82px;
      margin-bottom: 20px;
    }
    @media #{$small__mobile} {
      font-size: 70px;
      line-height: 70px;
      margin-bottom: 23px;
    }
    @media #{$extra__small2} {
      font-size: 53px;
      line-height: 55px;
    }
  }
  &--subtitle {
    font-size: 35px;
    line-height: 40px;
    @media #{$large__device} {
      font-size: 32px;
      line-height: 33px;
    }
    @media #{$medium__device} {
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 13px !important;
    }
    @media #{$small__mobile} {
      font-size: 28px;
      line-height: 30px;
      margin-bottom: 15px !important;
    }
    @media #{$extra__small2} {
      font-size: 24px;
      margin-bottom: 12px !important;
    }
  }
  &--desc {
    font-size: 17px;
    margin-bottom: 40px;
    @media #{$large__device} {
      margin-bottom: 35px;
    }
    @media #{$medium__device} {
      margin-bottom: 28px;
    }
    @media #{$small__mobile} {
      font-size: 16px;
      margin-bottom: 26px;
    }
    @media #{$extra__small2} {
      font-size: 15px;
      margin-bottom: 23px;
    }
  }
}

@import "../../assets/styles/variables.scss";

.about__section {
  &.mb-95 {
    @media #{$medium__device} {
      margin-bottom: 44px;
    }
    @media #{$small__mobile} {
      margin-bottom: 30px;
    }
  }
}
.about__top--bar {
  &.pb-60 {
    @media #{$medium__device} {
      padding-bottom: 44px;
    }
    @media #{$small__mobile} {
      padding-bottom: 53px;
    }
    @media #{$extra__small} {
      padding-bottom: 31px;
    }
  }
  &.mb-55 {
    @media #{$medium__device} {
      margin-bottom: 46px;
    }
    @media #{$small__mobile} {
      margin-bottom: 54px;
    }
  }
}
.about__thumb {
  @media #{$medium__device} {
    margin-bottom: 30px;
  }
  @media #{$small__mobile} {
    margin-bottom: 30px;
  }
  @media #{$extra__small} {
    margin-bottom: 25px;
  }
  &--img {
    border-radius: 10px;
  }
}
.about__section--title {
  &.mb-30 {
    @media #{$small__mobile} {
      margin-bottom: 22px;
    }
  }
}
.about__content {
  padding-left: 40px;
  @media #{$large__device} {
    padding-left: 0;
  }
  @media #{$medium__device} {
    padding-left: 0;
  }
  @media #{$small__mobile} {
    padding-left: 0;
  }
}
.about__info {
  &--list {
    @media #{$extra__small} {
      margin-bottom: 22px;
    }
    &__item {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 26px;
      color: #555;
      &:last-child {
        margin-bottom: 0;
      }
      @media #{$large__device} {
        font-size: 15px;
        line-height: 25px;
      }
      @media #{$small__mobile} {
        margin-bottom: 6px;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  &--ahthor {
    min-width: 85px;
    margin-right: 6px;
    display: inline-block;
    font-weight: 600;
    @media #{$large__device} {
      min-width: 75px;
      margin-right: 5px;
    }
    @media #{$small__mobile} {
      min-width: 79px;
    }
    @media #{$extra__small2} {
      min-width: 90px;
    }
  }
  &--link {
    &:hover {
      color: var(--secondary-color);
    }
  }
}

.about__skill {
  &--row {
    margin-right: -25px;
    margin-left: -25px;
    @media #{$extra__large__device} {
      margin-right: -12px;
      margin-left: -12px;
    }
    @media #{$large__device} {
      margin-right: -12px;
      margin-left: -12px;
    }
    @media #{$medium__device} {
      margin-right: -12px;
      margin-left: -12px;
    }
    @media #{$small__mobile} {
      margin-right: -12px;
      margin-left: -12px;
    }
  }
  &--col {
    padding-left: 25px;
    padding-right: 25px;
    @media #{$extra__large__device} {
      padding-left: 15px;
      padding-right: 15px;
    }
    @media #{$large__device} {
      padding-left: 12px;
      padding-right: 12px;
    }
    @media #{$medium__device} {
      padding-left: 12px;
      padding-right: 12px;
    }
    @media #{$small__mobile} {
      padding-left: 12px;
      padding-right: 12px;
    }
    @media #{$small__mobile} {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  &--list {
    @media #{$medium__device} {
      margin-bottom: 30px;
    }
    @media #{$small__mobile} {
      margin-bottom: 25px;
    }
  }
  &--link {
    font-weight: 600;
    &:hover {
      & .about__skill--link__icon {
        padding-left: 8px;
      }
    }
    &__icon {
      padding-left: 5px;
      transition: 0.3s;
    }
  }
  &--desc {
    @media #{$large__device} {
      font-size: 15px;
    }
  }
}

// Global CSS

@import "./variables.scss";

:root {
  --primary-color: #232323;
  --text-color2: #c5c5c5;
  --dark-color2: #171717;
  --dark-color3: #272727;
  --white-color: #ffffff;
  --secondary-color: #7e22ce;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  line-height: 24px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  font-family: $poppins;
  color: var(--primary-color);
}
.template__dark--color {
  background: var(--primary-color);
  color: var(--white-color);
  & p {
    color: var(--text-color2);
  }
  & h5 {
    color: var(--text-color2);
  }
  & .panel-menu {
    background: var(--primary-color);
  }
  & .header__sub--menu {
    background: var(--primary-color);
  }
}
.text__primary {
  color: var(--primary-color) !important;
}
.text__color2 {
  color: var(--text-color2) !important;
}
.template__dark--bg2 {
  background: var(--dark-color2) !important;
}
.template__dark--bg3 {
  background: var(--dark-color3) !important;
}
.border__color2 {
  border: 1px solid #4d4d4b !important;
}
.border__bottom--color2 {
  border-bottom: 1px solid #4d4d4b !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
  font-family: $lato;
}
h1 {
  font-size: 60px;
  line-height: 65px;
  font-weight: 700;
  @media #{$extra__large__device} {
    font-size: 50px;
    line-height: 50px;
  }
  @media #{$large__device} {
    font-size: 45px;
    line-height: 50px;
  }
  @media #{$medium__device} {
    font-size: 40px;
    line-height: 35px;
  }
  @media #{$small__mobile} {
    font-size: 30px;
    line-height: 35px;
  }
  @media #{$extra__small} {
    font-size: 28px;
    line-height: 30px;
  }
}
h2 {
  font-size: 40px;
  line-height: 45px;
  font-weight: 700;
  @media #{$large__device} {
    font-size: 38px;
    line-height: 40px;
  }
  @media #{$medium__device} {
    font-size: 32px;
    line-height: 35px;
  }
  @media #{$small__mobile} {
    font-size: 28px;
    line-height: 30px;
  }
  @media #{$extra__small} {
    font-size: 22px;
    line-height: 25px;
  }
}

h3 {
  font-size: 23px;
  line-height: 26px;
  font-weight: 700;
  @media #{$large__device} {
    font-size: 22px;
  }
  @media #{$medium__device} {
    font-size: 21px;
    line-height: 24px;
  }
  @media #{$small__mobile} {
    font-size: 20px;
    line-height: 22px;
  }
}
h4 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  @media #{$medium__device} {
    font-size: 18px;
    line-height: 20px;
  }
  @media #{$small__mobile} {
    font-size: 17px;
    line-height: 24px;
  }
}
h5 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  color: #555;
}
p {
  font-size: 16px;
  line-height: 26px;
  color: #555;
  @media #{$medium__device} {
    font-size: 16px;
  }
  @media #{$small__mobile} {
    font-size: 15px;
    line-height: 25px;
  }
}
p:last-child {
  margin-bottom: 0;
}

a,
button {
  color: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}
a,
button,
img,
input,
textarea,
span {
  transition: all 0.3s ease 0s;
  display: inline-block;
}
.btn:focus {
  box-shadow: none;
}
*:focus {
  outline: none !important;
}
a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
button,
input[type="submit"] {
  cursor: pointer;
}
img {
  max-width: 100%;
  height: auto;
}

ul,
ol {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
figure {
  margin: 0;
}
.bg-grey {
  background: $bg_grey_color;
}
.container {
  max-width: 1280px;
  @media #{$small__mobile} {
    max-width: 576px;
  }
}
.container-fluid.width-calc {
  width: calc(100% - 120px);
  @media #{$extra__large__inner} {
    width: calc(100% - 100px);
  }
  @media #{$extra__large__inner2} {
    width: calc(100% - 50px);
  }
  @media #{$large__device} {
    width: 100%;
  }
  @media #{$medium__device} {
    width: 100%;
  }
  @media #{$small__mobile} {
    width: 100%;
  }
}

.header__transparent {
  position: absolute;
  width: 100%;
  z-index: 9;
  top: 0;
}

.section__fixed {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  transition: all 0.25s ease;
  backface-visibility: hidden;
  perspective: 800px;
}

.section__mp {
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.action__btn {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: box-shadow, transform;
  padding: 10px 15px;
  cursor: pointer;
  background: linear-gradient(109.6deg, #7412cb 11.2%, #e62e83 91.2%);
  text-decoration: none;
  color: #ffffff;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  @media #{$small__mobile} {
    padding: 10px 17px;
    font-size: 13px;
  }
  &:hover {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.7);
    transform: scale(1.1);
    color: #ffffff;
  }
  &:focus {
    color: #ffffff;
  }
  &--icon {
    margin-left: 8px;
    @media #{$small__mobile} {
      margin-left: 6px;
    }
  }
}
.gradient__text {
  background: linear-gradient(
    to right,
    rgba(116, 18, 203, 1) 11.2%,
    rgba(230, 46, 131, 1) 91.2%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}
.text__secondary--color {
  color: var(--secondary-color);
}
.section__title {
  &.mb-50 {
    @media #{$small__mobile} {
      margin-bottom: 30px;
    }
  }
  &.mb-40 {
    @media #{$small__mobile} {
      margin-bottom: 30px;
    }
  }
  &.mb-25 {
    @media #{$small__mobile} {
      margin-bottom: 10px;
    }
  }
}

.section__subtitle {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  @media #{$small__mobile} {
    font-size: 16px;
    line-height: 22px;
  }
}
.section__title--text {
  font-weight: 900;
}

.gradient__border {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 50px;
    height: 3px;
    background: linear-gradient(
      109.6deg,
      rgba(116, 18, 203, 1) 11.2%,
      rgba(230, 46, 131, 1) 91.2%
    );
    bottom: 0;
    left: 0;
    transition: 0.4s;
  }
}

.swiper-pagination {
  bottom: 0 !important;
  & .swiper-pagination-bullet {
    width: 11px;
    height: 11px;
    margin: 0 7px !important;
    background: #999;
    opacity: 1;
    transition: 0.3s;
    &:hover {
      transform: scale(1.2);
      background: var(--secondary-color);
    }
    &.swiper-pagination-bullet-active {
      transform: scale(1.2);
      background: var(--secondary-color);
    }
  }
}
.custom-col {
  @media #{$extra__small2} {
    width: 100%;
  }
}
//Template Global Margin
.mb-100 {
  margin-bottom: 100px;
}
.mb-75 {
  margin-bottom: 75px;
}
.mb-95 {
  margin-bottom: 95px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-55 {
  margin-bottom: 55px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-18 {
  margin-bottom: 18px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-12 {
  margin-bottom: 12px;
  @media #{$small__mobile} {
    margin-bottom: 10px;
  }
}
.mb-10 {
  margin-bottom: 10px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-15 {
  padding-bottom: 15px;
}
.mb-0-important {
  margin-bottom: 0 !important;
}

@import "../variables.scss";

// hader css here
.header--sticky {
  transition: all 0.2s linear;
  &.sticky {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: #f3f3f5;
    transition: all 0.2s, top 0.4s linear;
    animation-name: fadeInDown;
    animation-duration: 900ms;
    animation-timing-function: cubic-bezier(0.2, 1, 0.22, 1);
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
    z-index: 99;
  }
}
// mobile menu css here
.page__dark--version__link {
  display: inline-block;
  vertical-align: middle;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  width: 50px;
  height: 50px;
  background: linear-gradient(
    109.6deg,
    rgba(116, 18, 203, 1) 11.2%,
    rgba(230, 46, 131, 1) 91.2%
  );
  color: var(--white-color);
  font-size: 22px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  margin: 0.4em;
  @media #{$small__mobile} {
    font-size: 20px;
  }
  &:hover {
    color: #ffffff;
    transform: scale(1.1);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.7);
  }
  &:focus {
    color: #ffffff;
  }
}
// mobile menu css end
.offcanvas__menu {
  overflow-y: auto;
  height: 100%;
}
.offcanvas__menu_ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.offcanvas__menu_li {
  position: relative;
  border-bottom: 1px solid #ddd;
}
.offcanvas__menu_li:first-child {
  border-top: 1px solid #ddd;
}

.offcanvas__menu_item {
  line-height: 1;
  display: block;
  padding: 15px 20px;
  text-transform: uppercase;
}
.offcanvas__sub_menu {
  margin: 0;
  padding: 0;
  height: 0;
  opacity: 0;
  transition: 0.3s;
  visibility: hidden;
}

.offcanvas__sub_menu_li {
  position: relative;
  border-top: 1px solid #ddd;
}
.offcanvas__sub_menu_item {
  line-height: 1;
  display: block;
  padding: 15px 0 15px 30px;
  color: #777;
  font-size: 14px;
}
.offcanvas__sub_menu_item ~ .offcanvas__sub_menu .offcanvas__sub_menu_item {
  padding-left: 50px;
}
.offcanvas__sub_menu_toggle {
  font-size: 20px;
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  width: 40px;
  height: 46px;
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
}
.offcanvas__sub_menu_toggle::before,
.offcanvas__sub_menu_toggle::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 2px;
  content: "";
  transition: 0.3s;
  transform: translateX(-50%) translateY(-50%);
  background-color: #000;
}
.offcanvas__sub_menu_toggle:not(.active)::after {
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
}
.offcanvas__menu_ul li.active > .offcanvas__sub_menu {
  visibility: visible;
  height: 100%;
  opacity: 1;
}
.offcanvas__menu_header {
  background: #000;
  text-align: center;
}
button.offcanvas__close_btn {
  background: none;
  color: #fff;
  border: none;
  font-size: 17px;
  text-transform: uppercase;
  width: 100%;
  padding: 10px 10px;
}
.open_sidebar {
  overflow-y: hidden;
}
.offcanvas__inner {
  height: 100%;
  padding-bottom: 45px;
}
button.mobile__menu--open__icon {
  border: none;
  background: #fff;
  box-shadow: 0 0 4px rgb(0 0 0 / 15%);
  border-radius: 3px;
}

.header__section {
  &--inner {
    padding: 15px 0;
  }
}

.nav__menu {
  &--items {
    margin-right: 30px;
    position: relative;
    &:last-child {
      margin-right: 0;
      @media #{$medium__device} {
        margin-bottom: 0 !important;
      }
      @media #{$small__mobile} {
        margin-bottom: 0 !important;
      }
    }
    @media #{$large__device} {
      margin-right: 24px;
    }
    @media #{$medium__device} {
      padding: 0 20px;
      margin-bottom: 10px !important;
    }
    @media #{$small__mobile} {
      padding: 0 20px;
      margin-bottom: 10px !important;
    }
    &:hover {
      & .header__sub--menu {
        visibility: visible;
        margin-top: 0;
        opacity: 1;
      }
      & .nav__menu--items__link {
        color: var(--secondary-color);
        &::before {
          opacity: 1;
          width: 100%;
          left: 0;
          right: 0;
        }
      }
    }
    &__link {
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      position: relative;
      padding-bottom: 6px;
      @media #{$medium__device} {
        display: block;
      }
      @media #{$small__mobile} {
        display: block;
      }
      &::before {
        position: absolute;
        content: "";
        width: 0;
        height: 2px;
        left: 50%;
        right: 50%;
        bottom: 0;
        background: var(--secondary-color);
        opacity: 0;
        transition: 0.3s;
      }
    }
  }
}

.header__sub--menu {
  position: absolute;
  z-index: 9;
  top: 100%;
  left: 0;
  visibility: hidden;
  width: 210px;
  margin: 0;
  margin-top: 20px;
  padding: 18px 20px;
  list-style: none;
  transition: 0.3s;
  opacity: 0;
  background-color: var(--white-color);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  &__link {
    font-size: 15px;
    display: block;
    padding: 9px 0;
    line-height: 20px;
    font-weight: 500;
    &:hover {
      color: var(--secondary-color);
    }
  }
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

a.main__logo--link {
  max-width: 150px;
}
.main__logo--img_dark,
.template__dark--color .main__logo--img {
  display: none;
}
.template__dark--color .main__logo--img_dark {
  display: block;
}

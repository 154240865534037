@import "../../assets/styles/variables.scss";
// portfolio css here
.portfolio__section {
  &.mb-75 {
    @media #{$medium__device} {
      margin-bottom: 56px;
    }
    @media #{$small__mobile} {
      margin-bottom: 36px;
    }
  }
}
.portfolio__position--img {
  display: none;
}

.portfolio__grid {
  &--items {
    margin-bottom: 25px;
  }
}

.portfolio__thumb {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  transition: 0.4s;
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgb(255, 255, 255) 13.2%,
      rgb(0, 0, 0) 74.2%
    );
    left: 0;
    top: 0;
    opacity: 0.6;
    pointer-events: none;
    z-index: 9;
    border-radius: 10px;
    transition: 0.4s;
  }
  &--link {
    @media #{$small__mobile} {
      width: 100%;
    }
  }
  &--img {
    border-radius: 10px;
    @media #{$small__mobile} {
      width: 100%;
    }
  }
  &:hover {
    box-shadow: inherit;
    & .portfolio__thumb--img {
      transform: scale(1.1);
    }
    &::before {
      opacity: 0.95;
      background: linear-gradient(
        109.6deg,
        rgba(116, 18, 203, 1) 11.2%,
        rgba(230, 46, 131, 1) 91.2%
      );
      border-radius: 10px;
    }
    & .portfolio__content {
      transform: translateY(0px);
    }
    & .portfolio__desc {
      opacity: 1;
    }
    & .portfolio__icon {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}

.portfolio__icon {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 9;
  transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
  transform: translateY(20px);
  opacity: 0;
  @media #{$small__mobile} {
    top: 15px;
    right: 15px;
  }
  @media #{$extra__small__inner} {
    top: 10px;
    right: 10px;
    z-index: 9;
  }
  &--link {
    width: 35px;
    height: 35px;
    background: var(--white-color);
    line-height: 35px;
    border-radius: 50%;
    text-align: center;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    border: none;
    &:hover {
      color: var(--secondary-color);
    }
    @media #{$small__mobile} {
      width: 31px;
      height: 31px;
      line-height: 31px;
    }
    @media #{$extra__small__inner} {
      width: 28px;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
    }
    &.text__primary {
      &:hover {
        color: var(--secondary-color) !important;
      }
    }
  }
}

.portfolio__content {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
  transform: translateY(45px);
  padding: 0 20px;
  @media #{$small__mobile} {
    bottom: 20px;
  }
  @media #{$extra__small} {
    bottom: 30px;
  }
  @media #{$extra__small__inner} {
    bottom: 20px;
    padding: 0 10px;
  }
}
.portfolio__subtitle {
  font-size: 15px;
  color: #ddd;
  font-weight: 500;
  margin-bottom: 13px;
  &:hover {
    color: var(--white-color);
  }
  @media #{$small__mobile} {
    font-size: 14px;
    margin-bottom: 5px;
  }
  @media #{$extra__small__inner} {
    font-size: 13px;
    margin-bottom: 4px;
    line-height: 20px;
  }
}
.portfolio__maintitle {
  font-weight: 900;
  margin-bottom: 20px;
  color: var(--white-color);
  @media #{$small__mobile} {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 22px;
  }
  @media #{$extra__small__inner} {
    margin-bottom: 8px;
    font-size: 17px;
    line-height: 20px;
  }
  &--link {
    &:hover {
      color: var(--white-color);
    }
  }
}

.portfolio__desc {
  color: var(--white-color);
  opacity: 0;
  transition: 0.4s;
  @media #{$small__mobile} {
    font-size: 14px;
    line-height: 23px;
  }
  @media #{$extra__small__inner} {
    font-size: 13px;
    line-height: 21px;
  }
}

.portfolio__filter {
  &.mb-50 {
    @media #{$small__mobile} {
      margin-bottom: 28px;
    }
  }
  @media #{$small__mobile} {
    flex-wrap: wrap;
  }
  &--button {
    border: 0;
    background: inherit;
    margin-right: 15px;
    display: inline-block;
    transition-duration: 0.3s;
    padding: 5px 20px;
    background: linear-gradient(
      109.6deg,
      rgba(255, 255, 255, 1) 11.2%,
      rgba(255, 255, 255, 1) 91.2%
    );
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: var(--white-color);
      background: linear-gradient(
        109.6deg,
        rgba(116, 18, 203, 1) 11.2%,
        rgba(230, 46, 131, 1) 91.2%
      );
    }
    &.active {
      color: var(--white-color);
      background: linear-gradient(
        109.6deg,
        rgba(116, 18, 203, 1) 11.2%,
        rgba(230, 46, 131, 1) 91.2%
      );
    }
    @media #{$small__mobile} {
      margin: 8px;
      font-size: 14px;
      padding: 4px 18px;
    }
  }
}

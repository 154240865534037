@import "../../assets/styles/variables.scss";
.hero__section {
  position: relative;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media #{$extra__large__device} {
    height: 810px;
  }
  @media #{$large__device} {
    height: 700px;
  }
  @media #{$medium__device} {
    height: 600px;
  }
  @media #{$small__mobile} {
    height: auto;
    padding-bottom: 83px;
    background-position: 55%;
  }
  @media #{$extra__small2} {
    padding-bottom: 64px;
  }
  &.mb-100 {
    @media #{$medium__device} {
      margin-bottom: 80px;
    }
    @media #{$small__mobile} {
      margin-bottom: 60px;
    }
  }
}
.hero__container {
  padding-top: 85px;
  @media #{$small__mobile} {
    padding-top: 168px;
  }
  @media #{$extra__small2} {
    padding-top: 148px;
  }
}

.hero__content {
  @media #{$extra__large__inner} {
    margin-left: 75px;
  }
  @media #{$extra__large__inner2} {
    padding-left: 110px;
  }
  @media #{$large__device} {
    padding-left: 110px;
  }
  @media #{$medium__device} {
    padding-left: 110px;
  }
  @media #{$small__mobile} {
    text-align: center;
    margin-bottom: 23px;
  }
  &--title {
    font-weight: 700;
    font-size: 60px;
    line-height: 50px;
    @media #{$extra__large__device} {
      font-size: 50px;
      line-height: 50px;
    }
    @media #{$large__device} {
      font-size: 45px;
      line-height: 35px;
    }
    @media #{$medium__device} {
      font-size: 40px;
      line-height: 35px;
    }
    @media #{$small__mobile} {
      font-size: 32px;
      line-height: 27px;
    }
    @media #{$extra__small} {
      font-size: 28px;
      line-height: 24px;
    }
    &.mb-25 {
      @media #{$medium__device} {
        margin-bottom: 20px;
      }
      @media #{$small__mobile} {
        margin-bottom: 20px;
      }
    }
    &__padding {
      padding: 0 !important;
    }
    &__color {
      color: var(--secondary-color);
      font-weight: 900;
    }
  }
  &--desc {
    width: 66%;
    @media #{$extra__large__inner2} {
      width: 78%;
    }
    @media #{$large__device} {
      width: 93%;
    }
    @media #{$medium__device} {
      width: 100%;
    }
    @media #{$small__mobile} {
      width: 100%;
      padding: 0 20px;
      display: none;
    }
    @media #{$extra__small} {
      padding: 0 10px;
    }
    &.mb-30 {
      @media #{$medium__device} {
        margin-bottom: 23px;
      }
      @media #{$small__mobile} {
        margin-bottom: 19px;
      }
    }
  }
}
.social__share {
  position: absolute;
  top: 54%;
  right: 80px;
  transform: translatey(-50%);
  @media #{$extra__large__inner} {
    right: 50px;
  }
  @media #{$extra__large__inner2} {
    right: 30px;
  }
  @media #{$large__device} {
    right: 30px;
  }
  @media #{$medium__device} {
    right: 30px;
  }
  @media #{$small__mobile} {
    position: inherit;
    top: inherit;
    transform: inherit;
    right: inherit;
  }
  &--inner {
    @media #{$small__mobile} {
      display: flex;
      justify-content: center;
    }
  }
  &--link {
    width: 38px;
    height: 38px;
    background: #ffffff;
    display: inline-block;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
    margin: 6px 5px;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.7);
    font-size: 17px;
    color: var(--primary-color);
    &:hover {
      background: linear-gradient(
        109.6deg,
        rgba(116, 18, 203, 1) 11.2%,
        rgba(230, 46, 131, 1) 91.2%
      );
      transform: scale(1.1);
      color: #ffffff;
    }
  }
}
.hero__contact--info {
  display: inline-block;
  position: absolute;
  text-align: center;
  transform: rotate(-90deg);
  top: 51%;
  left: 20px;
  @media #{$extra__large__device} {
    left: -26px;
  }
  @media #{$large__device } {
    left: -26px;
  }
  @media #{$medium__device} {
    left: -26px;
  }
  @media #{$small__mobile} {
    position: inherit;
    transform: inherit;
    top: inherit;
    left: inherit;
    margin-bottom: 20px;
    display: none;
  }
  &__text {
    margin-bottom: 5px;
    font-weight: 500;
  }
  &__number {
    &:hover {
      color: var(--secondary-color);
    }
  }
}

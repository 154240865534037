@import "../variables.scss";
// contact css here
.contact__section {
  &.mb-100 {
    @media #{$medium__device} {
      margin-bottom: 80px;
    }
    @media #{$small__mobile} {
      margin-bottom: 60px;
    }
  }
}
.contact__information {
  &.mb-60 {
    @media #{$medium__device} {
      margin-bottom: 20px;
    }
    @media #{$small__mobile} {
      margin-bottom: 30px;
    }
  }
}
.contact__info {
  &--items {
    background: var(--white-color);
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: 0.3s;
    @media #{$medium__device} {
      margin-bottom: 30px;
    }
    @media #{$small__mobile} {
      margin-bottom: 30px;
    }
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(
        109.6deg,
        rgba(116, 18, 203, 1) 11.2%,
        rgba(230, 46, 131, 1) 91.2%
      );
      border-radius: 10px;
      transition: 0.3s;
      opacity: 0;
    }
    &:hover {
      box-shadow: inherit;
      &::before {
        opacity: 1;
      }
      & .contact__info--title {
        color: var(--white-color);
        transition: 0.3s;
      }
      & .contact__info--icon {
        background: var(--white-color);
        color: var(--primary-color);
      }
      & .contact__info--text {
        color: var(--white-color);
      }
      & .contact__info--link {
        color: var(--white-color);
      }
    }
  }
  &--title {
    position: relative;
  }
  &--icon {
    font-size: 25px;
    width: 55px;
    height: 55px;
    line-height: 55px;
    background: linear-gradient(
      109.6deg,
      rgba(116, 18, 203, 1) 11.2%,
      rgba(230, 46, 131, 1) 91.2%
    );
    display: inline-block;
    color: var(--white-color);
    border-radius: 50%;
    margin-bottom: 18px;
    position: relative;
    transition: 0.3s;
  }
}

.contact__info--text {
  font-size: 15px;
  line-height: 28px;
  position: relative;
  transition: 0.3s;
}

.contact__form {
  background: var(--white-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  &--label {
    display: block;
    margin-bottom: 8px;
  }
  &--input {
    width: 100%;
    height: 50px;
    padding: 5px 15px;
    border-radius: 8px;
    &:focus {
      border-color: var(--secondary-color) !important;
    }
  }
  &--textarea {
    width: 100%;
    height: 150px;
    padding: 12px 15px;
    border-radius: 8px;
    resize: none;
    &:focus {
      border-color: var(--secondary-color) !important;
    }
  }
}
.contact__thumbnail {
  &--img {
    border-radius: 10px 10px 0 0;
  }
}
.contact__about {
  @media #{$medium__device} {
    margin-bottom: 30px;
  }
  @media #{$small__mobile} {
    margin-bottom: 30px;
  }
  &--content {
    background: var(--white-color);
    padding: 28px 25px;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    @media #{$small__mobile} {
      padding: 22px 25px;
    }
  }
  &--subtitle {
    font-size: 15px;
    color: #4c4c4c;
    @media #{$small__mobile} {
      margin-bottom: 13px;
    }
  }
  &--desc {
    line-height: 28px;
  }
}

.contact__map--iframe {
  width: 100%;
  height: 600px;
  margin-bottom: -7px;
  @media #{$medium__device} {
    height: 450px;
  }
  @media #{$small__mobile} {
    height: 350px;
  }
}

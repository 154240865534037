@import "../variables.scss";
// team css here
.team__section {
  &.mb-100 {
    @media #{$medium__device} {
      margin-bottom: 80px;
    }
    @media #{$small__mobile} {
      margin-bottom: 35px;
    }
  }
}
.team__items {
  @media #{$small__mobile} {
    margin-bottom: 25px;
  }
}
.team__thumb {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  &--img {
    border-radius: 10px;
    @media #{$small__mobile} {
      width: 100%;
    }
  }
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgb(255, 255, 255) 13.2%,
      rgb(0, 0, 0) 74.2%
    );
    left: 0;
    top: 0;
    opacity: 0.4;
    pointer-events: none;
    z-index: 9;
    border-radius: 10px;
    transition: 0.3s;
  }
  &:hover {
    &::before {
      opacity: 0.95;
      background: linear-gradient(
        109.6deg,
        rgba(116, 18, 203, 1) 11.2%,
        rgba(230, 46, 131, 1) 91.2%
      );
      border-radius: 10px;
    }
    & .team__content {
      transform: translateY(0px);
    }
    & .team__social {
      opacity: 1;
    }
  }
}
.team__content {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
  transform: translateY(30px);
  &--subtitle {
    color: #ddd;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
    @media #{$medium__device} {
      font-size: 14px;
    }
    @media #{$small__mobile} {
      margin-bottom: 7px;
      font-size: 14px;
    }
  }
  &--title {
    font-weight: 700;
    color: var(--white-color);
    @media #{$small__mobile} {
      margin-bottom: 14px;
    }
  }
}

.team__social {
  opacity: 0;
  transition: 0.3s;
  &--list {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
  &--link {
    font-size: 18px;
    color: var(--white-color);
    &:hover {
      color: var(--primary-color);
    }
  }
}

@import "../../assets/styles/variables.scss";
// resume section css here
.resume__section {
  &.mb-100 {
    @media #{$medium__device} {
      margin-bottom: 63px;
    }
    @media #{$small__mobile} {
      margin-bottom: 42px;
    }
  }
}
.resume__wrapper {
  position: relative;
  padding-left: 25px;
  @media #{$extra__small} {
    padding-left: 21px;
  }
}
.resume__education--experience {
  &.mb-50 {
    @media #{$medium__device} {
      margin-bottom: 26px;
    }
    @media #{$small__mobile} {
      margin-bottom: 0;
    }
  }
}
.resume__step {
  @media #{$medium__device} {
    margin-bottom: 50px;
  }
  @media #{$small__mobile} {
    margin-bottom: 55px;
  }
}
.resume__timeline {
  &--vertical {
    position: absolute;
    height: 100%;
    width: 2px;
    left: 0;
    top: 0;
    background: linear-gradient(
      109.6deg,
      rgba(116, 18, 203, 1) 11.2%,
      rgba(230, 46, 131, 1) 91.2%
    );
  }
  &--bullet {
    position: absolute;
    background: linear-gradient(
      109.6deg,
      rgba(116, 18, 203, 1) 11.2%,
      rgba(230, 46, 131, 1) 91.2%
    );
    width: 15px;
    height: 15px;
    border-radius: 50%;
    z-index: 9;
    top: 29px;
    left: -32px;
    @media #{$extra__small} {
      left: -28px;
    }
  }
}
.resume_maintitle {
  padding-bottom: 12px;
}
.resume__list {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  background: var(--white-color);
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 24px;
  position: relative;
  transition: 0.3s;
  @media #{$extra__small} {
    padding: 20px;
  }
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    transition: 0.3s;
    background: linear-gradient(
      109.6deg,
      rgba(116, 18, 203, 1) 11.2%,
      rgba(230, 46, 131, 1) 91.2%
    );
    opacity: 0;
  }
  &:hover {
    box-shadow: inherit;
    &::before {
      opacity: 1;
    }
    & .resume__list--subtitle {
      color: var(--white-color);
    }
    & .resume__dates {
      color: var(--white-color);
    }
    & .resume__list--maintitle {
      color: var(--white-color);
    }
    & .resume__maindesc {
      color: var(--white-color);
    }
  }
  &--maintitle {
    font-size: 21px;
    transition: 0.3s;
    @media #{$medium__device} {
      font-size: 19px;
    }
    @media #{$extra__small} {
      font-size: 18px;
    }
  }
  &--subtitle {
    font-size: 13px;
    letter-spacing: 0.5px;
    margin-bottom: 12px;
    line-height: 22px;
    transition: 0.3s;
    @media #{$extra__small} {
      line-height: 22px;
    }
  }
  &--heading {
    position: relative;
    @media #{$extra__small} {
      margin-bottom: 12px;
    }
  }
}
.resume__dates {
  font-weight: 400;
  margin-left: 10px;
}
.resume__maindesc {
  font-size: 16px;
  transition: 0.3s;
  @media #{$small__mobile} {
    font-size: 15px;
  }
  @media #{$extra__small} {
    font-size: 14px;
    line-height: 24px;
  }
}
.resume__desc {
  position: relative;
}
.resume__skill {
  &--step {
    @media #{$medium__device} {
      margin-bottom: 18px;
    }
    @media #{$small__mobile} {
      margin-bottom: 18px;
    }
  }
  &--title {
    padding-bottom: 12px;
  }
  &--subtitle {
    font-size: 15px;
    margin-bottom: 10px;
    @media #{$small__mobile} {
      font-size: 14px;
      margin-bottom: 8px;
    }
  }
}

.skill__progressbar {
  position: relative;
  border-radius: 4px;
  background: linear-gradient(
    109.6deg,
    rgba(116, 18, 203, 1) 11.2%,
    rgba(230, 46, 131, 1) 91.2%
  );
  &--width {
    position: absolute;
    right: 20px;
    font-size: 10px;
  }
}

.skill__progress {
  margin-bottom: 18px;
  &:last-child {
    margin-bottom: 0;
  }
  &--inner {
    height: 12px;
  }
}

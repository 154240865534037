@import "../../assets/styles/variables.scss";
// breadcrumb css here
.breadcrumb__area {
  background: #f7f7fb;
  padding: 100px 0;
  @media #{$medium__device} {
    padding: 75px 0;
  }
  @media #{$small__mobile} {
    padding: 70px 0;
  }
  @media #{$extra__small} {
    padding: 60px 0;
  }
  &.mb-100 {
    @media #{$medium__device} {
      margin-bottom: 80px;
    }
    @media #{$small__mobile} {
      margin-bottom: 60px;
    }
  }
  &.mb-95 {
    @media #{$medium__device} {
      margin-bottom: 75px;
    }
    @media #{$small__mobile} {
      margin-bottom: 55px;
    }
  }
}

.breadcrumb__text {
  padding-top: 75px;
  @media #{$medium__device} {
    padding-top: 82px;
  }
  @media #{$small__mobile} {
    padding-top: 78px;
  }
  @media #{$extra__small} {
    padding-top: 80px;
  }
}
.breadcrumb__title {
  position: relative;
  padding-bottom: 13px;
  font-size: 50px;
  line-height: 50px;
  @media #{$large__device} {
    font-size: 45px;
  }
  @media #{$medium__device} {
    font-size: 40px;
    line-height: 35px;
  }
  @media #{$small__mobile} {
    font-size: 30px;
    line-height: 35px;
  }
  @media #{$extra__small} {
    font-size: 28px;
    line-height: 30px;
  }
  &::before {
    position: absolute;
    content: "";
    width: 50px;
    height: 3px;
    background: linear-gradient(
      109.6deg,
      rgba(116, 18, 203, 1) 11.2%,
      rgba(230, 46, 131, 1) 91.2%
    );
    left: 50%;
    bottom: 0;
    transform: translatex(-50%);
  }
}
.breadcrumb__item {
  font-size: 16px;
  line-height: 22px;
  color: var(--secondary-color);
  margin-right: 15px;
  padding-right: 17px;
  position: relative;
  @media #{$small__mobile} {
    font-size: 15px;
  }
  &::before {
    position: absolute;
    content: "";
    width: 2px;
    height: 100%;
    background: var(--secondary-color);
    right: 0;
    transform: rotate(22deg);
    top: 0;
  }
  &:last-child {
    margin-right: 0;
    padding-right: 0;
    &::before {
      display: none;
    }
  }
  &.bread__dark--item {
    color: var(--white-color);
    &::before {
      background: var(--white-color);
    }
  }
}

.breadcrumb__link {
  font-weight: 500;
  color: #555;
  &:hover {
    color: var(--secondary-color);
  }
}

.bread__dark--link {
  &:hover {
    color: var(--white-color) !important;
  }
}

@import "../variables.scss";
// Single servives css here
.single__services--section {
  &.mb-95 {
    @media #{$medium__device} {
      margin-bottom: 75px;
    }
    @media #{$small__mobile} {
      margin-bottom: 55px;
    }
  }
}
.single__services--thumbnail {
  &.mb-30 {
    @media #{$small__mobile} {
      margin-bottom: 25px;
    }
  }
  &__img {
    border-radius: 10px;
  }
}
.single__services--content {
  &__title {
    &.mb-20 {
      @media #{$small__mobile} {
        margin-bottom: 15px;
      }
    }
  }
  &__desc {
    &.mb-20 {
      @media #{$small__mobile} {
        margin-bottom: 15px;
      }
    }
  }
}

.services__info {
  &--section {
    &.mb-100 {
      @media #{$medium__device} {
        margin-bottom: 80px;
      }
      @media #{$small__mobile} {
        margin-bottom: 60px;
      }
    }
  }
  &--thumbnail {
    @media #{$small__mobile} {
      margin-bottom: 25px;
    }
    &__img {
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }
  }
}

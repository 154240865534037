.services__video--thumb {
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .services__video--section.mb-100 {
    margin-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .services__video--section.mb-100 {
    margin-bottom: 60px;
  }
}

.services__video--img {
  border-radius: 10px;
}

.video__play--icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translatey(-50%) translatex(-50%);
}

.video__play--link {
  width: 85px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  background: linear-gradient(109.6deg, #7412cb 11.2%, #e62e83 91.2%);
  border-radius: 50%;
  font-size: 30px;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: box-shadow, transform;
  border: none;
}
.video__play--link:hover {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.7);
  transform: scale(1.1);
}
.video__play--link__icon {
  color: var(--white-color);
}
@media only screen and (max-width: 767px) {
  .video__play--link {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 25px;
  }
}

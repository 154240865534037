@import "../variables.scss";
// testimonial css here
.testimonial__section {
  background: #f7f7fb;
  padding: 95px 0;
  @media #{$medium__device} {
    padding: 75px 0;
  }
  @media #{$small__mobile} {
    padding: 55px 0;
  }
  &.mb-95 {
    @media #{$medium__device} {
      margin-bottom: 75px;
    }
    @media #{$small__mobile} {
      margin-bottom: 55px;
    }
  }
}
.testimonial__items {
  @media #{$small__mobile} {
    flex-direction: column;
  }
}
.testimonial__info {
  background: var(--white-color);
  padding: 25px;
  border-radius: 10px;
  width: 360px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  @media #{$medium__device} {
    padding: 15px;
    width: 240px;
  }
  @media #{$small__mobile} {
    padding: 20px;
    margin: 0 auto 20px;
  }
}

.testimonial__content {
  width: calc(100% - 360px);
  background: var(--white-color);
  margin-left: 40px;
  padding: 40px 30px;
  border-radius: 10px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  @media #{$large__device} {
    margin-left: 30px;
    padding: 40px 25px;
  }
  @media #{$medium__device} {
    width: calc(100% - 250px);
    margin-left: 20px;
    padding: 20px 25px;
  }
  @media #{$small__mobile} {
    width: 100%;
    margin-left: 0;
  }
  @media #{$extra__small} {
    padding: 30px 18px;
  }
  &--top {
    position: relative;
    margin-bottom: 17px;
    padding-bottom: 18px;
    @media #{$medium__device} {
      margin-bottom: 14px;
      padding-bottom: 14px;
    }
  }
}

.testimonial__quote--icon {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 60px;
  color: #999;
  @media #{$small__mobile} {
    font-size: 40px;
  }
  @media #{$extra__small} {
    font-size: 25px;
  }
}

.testimonial__wrapper {
  padding: 25px 0 45px;
}

.testimonial__thumb {
  &--img {
    border-radius: 5px;
  }
}
.testimonial__author {
  padding-top: 15px;
  &--maintitle {
    font-size: 21px;
    line-height: 24px;
    margin-bottom: 7px;
  }
  &--subtitle {
    font-size: 15px;
    line-height: 24px;
  }
}

.testimonial__desc {
  font-size: 17px;
  line-height: 30px;
  margin-bottom: 18px;
  @media #{$medium__device} {
    font-size: 15px;
    line-height: 26px;
    margin-bottom: 13px;
  }
  @media #{$small__mobile} {
    font-size: 15px;
    line-height: 26px;
    margin-bottom: 13px;
  }
}

.testimonial__rating {
  &--list {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
  &--icon {
    font-size: 18px;
    color: #ffc107;
    &:hover {
      color: var(--secondary-color);
    }
  }
}

.card-box {
  background: var(--white-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 30px rgb(0 0 0 / 10%);
  position: relative;
  transition: 0.3s;
}
.card-box:hover {
  box-shadow: inherit;
}
.card-box:hover::before {
  opacity: 1;
}
.card-box::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
  transition: 0.3s;
  background: linear-gradient(109.6deg, #7412cb 11.2%, #e62e83 91.2%);
  opacity: 0;
}

@import "../variables.scss";
// services css here
.services__section {
  background: #f7f7fb;
  padding: 95px 0 76px;
  @media #{$medium__device} {
    padding: 74px 0 56px;
  }
  @media #{$small__mobile} {
    padding: 55px 0 37px;
  }
  &.mb-95 {
    @media #{$medium__device} {
      margin-bottom: 77px;
    }
    @media #{$small__mobile} {
      margin-bottom: 56px;
    }
  }
  &.services__bg--none {
    background: none;
    padding: 0;
  }
}

.services__box {
  background: var(--white-color);
  border-radius: 10px;
  margin-bottom: 24px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  &--inner {
    transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
    transform: translateY(20px);
    padding: 25px 30px;
    @media #{$small__mobile} {
      padding: 20px 20px;
    }
    @media #{$extra__small__inner} {
      padding: 15px 15px;
    }
  }
  &:hover {
    box-shadow: inherit;
    & .services__box--inner {
      transform: translateY(0px);
    }
    &::before {
      opacity: 1;
    }
    & .services__icon {
      background: var(--white-color);
      color: var(--primary-color);
    }
    & .services__box--title {
      color: var(--white-color);
      &::before {
        background: var(--white-color);
      }
    }
    & .services__box--desc {
      color: var(--white-color);
    }
    & .services__box--link {
      color: var(--white-color);
      opacity: 1;
      &__icon {
        color: var(--white-color);
      }
    }
    & .services__box--style--icon {
      color: var(--white-color);
    }
  }
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: linear-gradient(
      109.6deg,
      rgba(116, 18, 203, 1) 11.2%,
      rgba(230, 46, 131, 1) 91.2%
    );
    transition: 0.4s;
    opacity: 0;
    border-radius: 10px;
  }
  &--title {
    font-size: 20px;
    position: relative;
    padding-bottom: 12px;
    transition: 0.4s;
    @media #{$extra__small__inner} {
      font-size: 18px;
      padding-bottom: 10px;
      margin-bottom: 14px;
    }
    &::before {
      position: absolute;
      content: "";
      width: 50px;
      height: 3px;
      background: linear-gradient(
        109.6deg,
        rgba(116, 18, 203, 1) 11.2%,
        rgba(230, 46, 131, 1) 91.2%
      );
      bottom: 0;
      left: 0;
      transition: 0.4s;
    }
  }
  &--desc {
    position: relative;
    transition: 0.4s;
    @media #{$extra__small__inner} {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }
  &--link {
    opacity: 0;
    transition: 0.4s;
    position: relative;
    &:hover {
      & .services__box--link__icon {
        padding-left: 8px;
      }
    }
    &__icon {
      padding-left: 5px;
      transition: 0.3s;
      vertical-align: middle;
      display: inline-block;
    }
  }
}
.services__icon {
  width: 60px;
  height: 60px;
  text-align: center;
  background: linear-gradient(
    109.6deg,
    rgba(116, 18, 203, 1) 11.2%,
    rgba(230, 46, 131, 1) 91.2%
  );
  color: var(--white-color);
  font-size: 25px;
  display: inline-block;
  line-height: 60px;
  border-radius: 50%;
  margin-bottom: 18px;
  position: relative;
  transition: 0.4s;
  @media #{$small__mobile} {
    margin-bottom: 15px;
  }
  @media #{$extra__small__inner} {
    font-size: 22px;
    line-height: 52px;
    width: 52px;
    height: 52px;
    margin-bottom: 14px;
  }
}

// single services css here
.services__details--section {
  &.mb-100 {
    @media #{$medium__device} {
      margin-bottom: 75px;
    }
    @media #{$small__mobile} {
      margin-bottom: 55px;
    }
  }
}
.services__thumbnail {
  @media #{$medium__device} {
    margin-bottom: 30px;
  }
  @media #{$small__mobile} {
    margin-bottom: 25px;
  }
  &--img {
    border-radius: 10px;
  }
}

.services__content {
  &--title {
    &.mb-20 {
      @media #{$small__mobile} {
        margin-bottom: 15px;
      }
    }
  }
  &--desc {
    &.mb-20 {
      @media #{$small__mobile} {
        margin-bottom: 15px;
      }
    }
    &.mb-25 {
      @media #{$small__mobile} {
        margin-bottom: 15px;
      }
    }
  }
}

.services__video--thumb {
  position: relative;
}

.services__video--section {
  &.mb-100 {
    @media #{$medium__device} {
      margin-bottom: 80px;
    }
    @media #{$small__mobile} {
      margin-bottom: 60px;
    }
  }
}
.services__video--img {
  border-radius: 10px;
}
.video__play--icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translatey(-50%) translatex(-50%);
}
.video__play--link {
  width: 85px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  background: linear-gradient(
    109.6deg,
    rgba(116, 18, 203, 1) 11.2%,
    rgba(230, 46, 131, 1) 91.2%
  );
  border-radius: 50%;
  font-size: 30px;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: box-shadow, transform;
  margin: 0.4em;
  &:hover {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.7);
    transform: scale(1.1);
  }
  &__icon {
    color: var(--white-color);
  }
  @media #{$small__mobile} {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 25px;
  }
}

// services page css here
.services__box--style {
  box-shadow: inherit;
  text-align: center;
  background: inherit;
  &__title {
    &::before {
      left: 50%;
      transform: translatex(-50%);
    }
  }
  &--icon {
    color: var(--secondary-color);
    font-size: 45px;
    margin-bottom: 20px;
    display: inline-block;
  }
}

@import "../variables.scss";
// blog css here
.blog__section {
  background: #f7f7fb;
  padding: 95px 0;
  @media #{$medium__device} {
    padding: 75px 0;
  }
  @media #{$small__mobile} {
    padding: 55px 0;
  }
  &.mb-95 {
    @media #{$medium__device} {
      margin-bottom: 75px;
    }
    @media #{$small__mobile} {
      margin-bottom: 55px;
    }
  }
}
.blog__container {
  padding-bottom: 50px;
}
.blog__items {
  &:hover {
    & .blog__thumb--img {
      transform: scale(1.1);
    }
  }
}
.blog__thumb {
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  &--img {
    border-radius: 10px 10px 0 0;
  }
}
.blog__date--meta {
  position: absolute;
  top: 15px;
  left: 15px;
}
.blog__date {
  &--inner {
    width: 50px;
    height: 50px;
    background: var(--white-color);
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 5px;
    padding: 6px;
  }
  &--days {
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 4px;
  }
}
.blog__month {
  display: block;
  font-size: 13px;
  color: #555;
  line-height: 17px;
  text-transform: uppercase;
}
.blog__contrent {
  background: var(--white-color);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  padding: 25px 20px;
  border-radius: 0 0 10px 10px;
  &--tag {
    background: linear-gradient(
      109.6deg,
      rgba(116, 18, 203, 1) 11.2%,
      rgba(230, 46, 131, 1) 91.2%
    );
    display: inline-block;
    color: var(--white-color);
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    padding: 3px 14px;
    border-radius: 12px;
    margin-bottom: 15px;
  }
}

.blog__desc {
  margin-bottom: 18px;
}

.blog__manititle {
  margin-bottom: 16px;
  &--link {
    &:hover {
      color: var(--secondary-color);
    }
  }
}

.blog__readmore {
  font-weight: 500;
  &:hover {
    color: var(--secondary-color);
    & .blog__link--icon {
      padding-left: 5px;
    }
  }
}
.blog__link--icon {
  vertical-align: middle;
  padding-left: 3px;
  transition: 0.3s;
}

.blog__page--items {
  margin-bottom: 30px;
}
.blog__page--section {
  &.mb-70 {
    @media #{$medium__device} {
      margin-bottom: 50px;
    }
    @media #{$small__mobile} {
      margin-bottom: 30px;
    }
  }
}
